// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collection-indexed-js": () => import("./../../../src/pages/collection/indexed.js" /* webpackChunkName: "component---src-pages-collection-indexed-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-collection-search-js": () => import("./../../../src/pages/collection/search.js" /* webpackChunkName: "component---src-pages-collection-search-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-item-js": () => import("./../../../src/templates/Item.js" /* webpackChunkName: "component---src-templates-item-js" */),
  "component---src-templates-taxon-js": () => import("./../../../src/templates/Taxon.js" /* webpackChunkName: "component---src-templates-taxon-js" */),
  "component---src-templates-value-js": () => import("./../../../src/templates/Value.js" /* webpackChunkName: "component---src-templates-value-js" */),
  "component---src-templates-value-ur-ls-js": () => import("./../../../src/templates/ValueURLs.js" /* webpackChunkName: "component---src-templates-value-ur-ls-js" */)
}

